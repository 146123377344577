import * as Sentry from '@sentry/vue'
import HighchartsVue from 'highcharts-vue'
import {
    MCBreadCrumbs,
    MCButton,
    MCCheckbox,
    MCCheckboxGroup,
    MCDatePicker,
    MCDropdownMultiple,
    MCDropdownSingle,
    MCIcon,
    MCInfoBanner,
    MCLabelText,
    MCModal,
    MCNumberInput,
    MCPaginator,
    MCProgress,
    MCRadioButtonGroup,
    MCSpinner,
    MCTaskCounter,
    MCTextArea,
    MCTextInput,
    MCToggle,
} from 'moen-components'
import { createPinia } from 'pinia'
import { createApp } from 'vue'
import Vue3Toastify, { type ToastContainerOptions } from 'vue3-toastify'

// import AgGridRemoveElement from '@/components/agGridCustomComponents/remove.js'
import BreadCrumbs from '@/components/generic/BreadCrumbs.vue'
import LCMobileHeader from '@/components/generic/LCMobileHeader.vue'

import App from './App.vue'
import AnimateDirective from './directives/animate'
import ClickOutsideDirective from './directives/click-outside'
import { i18n } from './i18n'
// eslint-disable-next-line import/order
import router from './router'

import 'vue3-toastify/dist/index.css'
import 'moen-components/dist/style.css'
import 'vue3-carousel/dist/carousel.css'

// eslint-disable-next-line import/no-duplicates
import 'ag-charts-enterprise'
// eslint-disable-next-line import/order, import/no-duplicates
import { LicenseManager } from 'ag-charts-enterprise'

const API_KEY = import.meta.env.VITE_APP_AG_GRID_API_KEY as string
LicenseManager.setLicenseKey(API_KEY)

const app = createApp(App)

// Custom AG Grid element
// aipp.component('AgGridRemoveElement', AgGridRemoveElement)
// app.component('AgGridPillElement', AgGridPillElement)

const url = window.location.href
if (url.includes('v4.m-link.no')) {
    Sentry.init({
        app,
        dsn: 'https://0ba357d46b252b64123af6c173ae2b2b@o510551.ingest.us.sentry.io/4508698973241344',
        integrations: [Sentry.browserTracingIntegration({ router }), Sentry.replayIntegration()],
        tracesSampleRate: 1.0,
        tracePropagationTargets: ['v4.m-link.no'],
        replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
        replaysOnErrorSampleRate: 1.0,
    })
}

// @ts-expect-error-next-line
app.use(HighchartsVue)

const pinia = createPinia()

// Moen Components available globally
app.component('MCBreadCrumbs', MCBreadCrumbs)
app.component('LCMobileHeader', LCMobileHeader)
app.component('MCButton', MCButton)
app.component('MCCheckbox', MCCheckbox)
app.component('MCCheckboxGroup', MCCheckboxGroup)
app.component('MCDatePicker', MCDatePicker)
app.component('MCDropdownMultiple', MCDropdownMultiple)
app.component('MCDropdownSingle', MCDropdownSingle)
app.component('MCIcon', MCIcon)
app.component('MCInfoBanner', MCInfoBanner)
app.component('MCLabelText', MCLabelText)
app.component('MCNumberInput', MCNumberInput)
app.component('MCModal', MCModal)
app.component('MCPaginator', MCPaginator)
app.component('MCProgress', MCProgress)
app.component('MCRadioButtonGroup', MCRadioButtonGroup)
app.component('MCSpinner', MCSpinner)
app.component('MCTaskCounter', MCTaskCounter)
app.component('MCTextArea', MCTextArea)
app.component('MCTextInput', MCTextInput)
app.component('MCToggle', MCToggle)

// other components
app.component('BreadCrumbs', BreadCrumbs)

// directives
app.directive('click-outside', ClickOutsideDirective)
app.directive('animate', AnimateDirective)

// global error handling
app.config.errorHandler = (err: any, _vm, _info) => {
    // this is an attempt to fix the issue with chunk loading (common for SPA websites) for new deployments
    if (err?.message.includes('Failed to fetch dynamically imported module') || err?.message.includes('Unable to preload CSS')) {
        console.warn('Chunk load failed (there is probably a newer version on the server) - forcing refresh...')
        // force refresh
        window.location.reload()
    }
}

app.use(pinia)
app.use(i18n)
app.use(router)
app.use(Vue3Toastify, {
    autoClose: 3000,
    clearOnUrlChange: false,
} as ToastContainerOptions)

app.mount('#app')
