import * as Sentry from '@sentry/vue'

import { api } from './ApiSettings'
import http from './http'

import type { UUIDv4 } from '@/modules/CORE/company/interfaces/IGeneric'
import type { ISearchResponseDocument } from '@/modules/CORE/search/interfaces/ISearch'
import type {
    IDocument,
    IDocumentBasic,
    IDocumentBasicPatch,
    IDocumentCreate,
    IExpiredDocument,
    IFolder,
} from '@/modules/MAINTENANCE/documents/interfaces/IDocument'
import type { AxiosResponse } from 'axios'
import type { IMCDropdownMultipleOption } from 'moen-components/dist/dropdowns/IMCDropdownMultiple'

import { assert } from '@/utils'

export default {
    deleteDocument: async (id: UUIDv4): Promise<UUIDv4 | string | undefined> => {
        const url = api(`v1/documents/${id}/`)
        const response: AxiosResponse | undefined = await http.delete(url)
        if (response?.status === 204) {
            return id
        } else {
            return undefined
        }
    },
    createDocument: async (document: IDocumentCreate): Promise<IDocument | undefined> => {
        const url = api('v1/documents/')
        let response: AxiosResponse | null
        let doc: IDocument | undefined
        try {
            const json = JSON.stringify(document)
            response = await http.post(url, json)
            if (response.status === 200) {
                doc = response.data
            }
        } catch (e) {
            console.error('Failed to create new document.')
        }

        return doc
    },
    patchDocument: async (document: IDocumentBasicPatch): Promise<AxiosResponse> => {
        const url = api(`v1/documents/${document.id}/`)
        let response: AxiosResponse | undefined
        try {
            response = await http.patch(url, document)
            return response
        } catch (error: any) {
            throw new Error(error.response?.data || error.message)
        }
    },
    fetchDocumentsExpiredByFacilityId: async (facilityId: string): Promise<IDocument[] | undefined> => {
        if (!facilityId) return
        const url = api(`v2/facilities/${facilityId}/documents/expired/`)
        const errorMessage = 'Failed to get expired documents list'
        let response: Response | undefined
        let documents: IDocument[] | undefined
        try {
            response = await http.get(url)
            if (response.ok) {
                documents = await response.json()
            }
        } catch (e) {
            console.error(errorMessage)
        }

        return documents
    },
    fetchDocumentsByFacilityId: async (facilityId: string): Promise<any[] | undefined> => {
        if (!facilityId) return
        const url = api(`v2/facilities/${facilityId}/documents/`)
        const errorMessage = 'Failed to get documents list'
        let response: Response | undefined
        let documents: IDocument[] | undefined
        try {
            response = await http.get(url)
            if (response.ok) {
                documents = await response.json()
            }
        } catch (e) {
            console.error(errorMessage)
        }

        return documents
    },
    fetchExpiredDocuments: async (limit: number = 0): Promise<IExpiredDocument[]> => {
        // this will fetch all expired documents for all facilities that the user has access to
        const limitQuery = limit > 0 ? `?limit=${limit}` : ''
        const url = api(`v4/expired-documents${limitQuery}`)
        let response: Response | undefined
        let documents: IExpiredDocument[] = []
        try {
            response = await http.get(url)
            if (response.ok) {
                documents = await response.json()
            }
        } catch (e) {
            console.error('Failed to fetch expired documents.')
        }
        return documents
    },
    searchDocumentByFacilityIds: async (
        companyId: UUIDv4,
        facilityIds: any[],
        searchText: any,
        force_normal_search: boolean,
    ): Promise<ISearchResponseDocument[] | undefined> => {
        const url = api('v1/documents-search-in-facilities')
        let response: AxiosResponse | undefined
        let documents: any[] = []
        try {
            response = await http.post(url, {
                facility_ids: facilityIds,
                search: searchText,
                force_normal_search,
                company_id: companyId,
            })
            if (response.status === 200) {
                documents = await response.data
            }
        } catch (e) {
            console.error('Failed to search documents for facilities.')
        }

        return documents
    },

    fetchDocumentsPaginatedByFacilityId: async (
        facilityId: string | UUIDv4,
        page: number,
        pageSize: number,
        direction: string,
        sortBy: string,
        filterByType: string,
        searchText: string,
        documentId: UUIDv4 | string,
        folderId: UUIDv4 | string,
    ): Promise<any> => {
        if (!facilityId) return
        const fields = 'id,name,description,type,created,starts,expires,upload,equipment,discarded,folder,permissions'
        const filter = filterByType !== '' ? `__AND__type:${filterByType}` : ''

        const url = api(
            `v4/facilities/${facilityId}/documents/pagination/?search=${searchText + filter}&page=${page}&page_size=${pageSize}&sort=${sortBy}&dir=${direction}&fields=${fields}&document_id=${documentId}&folder_id=${folderId}`,
        )

        const errorMessage = 'Failed to get documents list'
        let response: Response | undefined
        let documents: IDocumentBasic[] | undefined
        try {
            response = await http.get(url)
            if (response.ok) {
                documents = await response.json()
            }
        } catch (e) {
            console.error(errorMessage)
        }

        return documents
    },
    fetchDocumentById: async (id: UUIDv4): Promise<IDocument | undefined> => {
        const url = api(`v1/documents/${id}/`)
        const errorMessage = `Failed to get document ${id}.`
        let response: Response | undefined
        let document: IDocument | undefined
        try {
            response = await http.get(url)
            if (response.ok) {
                document = await response.json()
            }
        } catch (e) {
            console.error(errorMessage)
        }

        return document
    },
    fetchDocumentsDropdownByFacilityId: async (facilityId: UUIDv4, search: string): Promise<IMCDropdownMultipleOption[]> => {
        assert(facilityId !== undefined, 'facilityId is required')
        const url = api(`v4/facility/${facilityId}/documents/dropdown?search=${search}`)
        const errorMessage = 'Failed to get documents dropdown list'

        try {
            const response = await http.get(url)
            if (!response.ok) {
                throw new Error(errorMessage)
            }
            const documents: IMCDropdownMultipleOption[] = await response.json()
            return documents
        } catch (error) {
            console.error(errorMessage, error)
            return []
        }
    },
    fetchDocumentFoldersByFacilityId: async (facilityId: UUIDv4): Promise<IFolder[]> => {
        assert(facilityId !== undefined, 'facilityId is required')
        const url = api(`v4/facilities/${facilityId}/document/folders/`)
        const errorMessage = 'Failed to get document folders list'

        try {
            const response = await http.get(url)
            if (!response.ok) {
                throw new Error(errorMessage)
            }
            const documents: IFolder[] = await response.json()
            return documents
        } catch (error) {
            Sentry.captureException(error)
            console.error(errorMessage, error)
        }
    },

    createNewFolder: async (facilityId: UUIDv4, newFolderName: string, parentFolderId: UUIDv4 | null = null): Promise<AxiosResponse> => {
        const url = api(`v4/facilities/${facilityId}/document/folders/`)
        return await http.post(url, { facility: facilityId, name: newFolderName, parent: parentFolderId })
    },

    updateFolderName: async (facilityId: UUIDv4, folderId: UUIDv4, newName: string): Promise<AxiosResponse> => {
        const url = api(`v4/facilities/${facilityId}/document/folders/`)
        return await http.patch(url, { name: newName, folder_id: folderId })
    },
    deleteFolder: async (facilityId: UUIDv4, folderId: UUIDv4 | number): Promise<AxiosResponse> => {
        const url = api(`v4/facilities/${facilityId}/document/folders/`)
        return await http.patch(url, { folder_id: folderId, delete: true })
    },
    fetchSelectedDocumentsData: async (facilityId: UUIDv4, documentIds: UUIDv4[]): Promise<AxiosResponse> => {
        const url = api(`v4/facilities/${facilityId}/document/folders/`)
        return await http.post(url, { document_ids: documentIds })
    },
    fetchDocumentsForFacilityDropdown: async (facilityId: UUIDv4): Promise<IMCDropdownMultipleOption[]> => {
        assert(facilityId !== undefined, 'facilityId is required')
        const url = api(`v4/facility/${facilityId}/documents/search/dropdown`)
        const errorMessage = 'Failed to get documents dropdown list'

        try {
            const response = await http.get(url)
            if (!response.ok) {
                throw new Error(errorMessage)
            }
            const documents: IMCDropdownMultipleOption[] = await response.json()
            return documents
        } catch (error) {
            console.error(errorMessage, error)
            return []
        }
    },
}
